<template>
   <div class="xtx-cart-page">
    <div class="container">
      <div class="aboutUs"></div>
      <p class="top-center">CONTACT US</p>
      <p class="company_text">联系我们</p>
      <div class="tel_content">
        <div class="left_tel">
          <div class="tel_one">
            <div class="tel_top-text"><img src="../../assets/images/address.png"/><span style="padding-left: 10px;font-size: 18px;">地址</span></div>
            <div class="tel_btn_text">湖南省长沙市高新区中电软件园5栋4楼</div>
          </div>
          <div class="tel_one">
            <div class="tel_top-text"><img src="../../assets/images/talke.png"/><span style="padding-left: 10px;font-size: 18px;">商务洽谈</span></div>
            <div class="tel_btn_text" style="margin-left:3px">15084907357</div>
          </div>
          <div class="tel_one">
            <div class="tel_top-text"><span style="padding-left: 10px;font-size: 18px;"></span></div>
            <div class="tel_btn_text" style="margin-left:3px"></div>
          </div>
          <div class="tel_one">
            <div class="tel_top-text"><img src="../../assets/images/phone.png"/><span style="padding-left: 10px;font-size: 18px;">销售热线</span></div>
            <div class="tel_btn_text" style="margin-left:3px">13548721291</div>
          </div>
        </div>
        <div class="right_logo">
          <div class="logo_c"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XtxGoodsPage',

  setup () { }
}
</script>

<style scoped lang='less'>
.container{
  width: 1024px;
  height: 100%;
  p{
    color: #646464;
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 36px;
  }
  .aboutUs{
    width: 1024px;
    height: 400px;
    background: url('../../assets/images/tel_us.png') no-repeat center / contain;
  }
  .company_text{
    text-align: center;
    font-size: 30px;
  }
  .top-center{
    text-align: center;
    font-size: 40px;
    color: #0d4596;
  }
  .tel_content{
    width: 100%;
    height: 300px;
    display: flex;
    .left_tel{
      flex: 0.7;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-top: 40px;
      .tel_one{
        width: 340px;
        height: 80px;
        .tel_top-text{
          width: 100%;
          height: 35px;
          line-height: 35px;
        }
        .tel_btn_text{
          width: 100%;
          height: 45px;
          font-size: 22px;
          line-height: 45px;
        }
      }
      .tel_one:first-child{
        width: 380px;
        height: 80px;
        .tel_top-text{
          width: 100%;
          height: 35px;
          line-height: 35px;
        }
        .tel_btn_text{
          width: 100%;
          height: 45px;
          font-size: 22px;
          line-height: 45px;
        }
      }
      .tel_one:nth-child(2){
        width: 295px;
        height: 80px;
        padding-left: 80px;
        .tel_top-text{
          width: 100%;
          height: 35px;
          line-height: 35px;
        }
        .tel_btn_text{
          width: 100%;
          height: 45px;
          font-size: 22px;
          line-height: 45px;
        }
      }
      .tel_one:nth-child(3){
        width: 380px;
        height: 80px;
        .tel_top-text{
          width: 100%;
          height: 35px;
          line-height: 35px;
        }
        .tel_btn_text{
          width: 100%;
          height: 45px;
          font-size: 22px;
          line-height: 45px;
        }
      }
      .tel_one:nth-child(4){
        width: 295px;
        height: 80px;
        padding-left: 80px;
        .tel_top-text{
          width: 100%;
          height: 35px;
          line-height: 35px;
        }
        .tel_btn_text{
          width: 100%;
          height: 45px;
          font-size: 22px;
          line-height: 45px;
        }
      }
    }
    .right_logo{
      flex: 0.3;
      .logo_c{
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 50px;
        background: url(../../assets/images/logo.png) no-repeat center / contain;
      }
    }
  }
}
</style>
